import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {
  HsdDownloadDialogComponent,
  HsdDownloadDialogData,
} from '@commons/hsd-download-dialog/hsd-download-dialog.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {CommonsSearchDataset, CommonsSearchProject} from '@shared/types/commons-types';
import {User} from '@shared/types/user';
import {lastValueFrom, Observable, shareReplay} from 'rxjs';
import {ProjectResponse} from '@services/landing-service';
import {SensitivityLevel as SensitivityLevelEnum} from '@shared/labels/sensitivity-level';
import {isLocked as _isLocked} from '@utilities/commons-dataset-util';

@Component({
  selector: 'app-datasets-card',
  templateUrl: './datasets-card.component.html',
  styleUrls: ['./datasets-card.component.scss'],
})
export class DatasetsCardComponent {
  datasetListMode: 'tiles' | 'table' = 'tiles';
  @Input() datasetsDataSource: MatTableDataSource<CommonsSearchDataset>;
  @Input() datasets: CommonsSearchDataset[];
  @Input() displayedColumns: string[];
  @Input() project: ProjectResponse;
  @Input() projectWithPermissions?: CommonsSearchProject;
  @Input() publicCommons: boolean = true;
  @Output() reloadEmit = new EventEmitter<any>();
  SensitivityLevel = SensitivityLevelEnum;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public cas: CommonsApiService,
    public dialog: MatDialog,
  ) {}

  showDataset(dataset?: CommonsSearchDataset) {
    const path = dataset?.id ? ['dataset', dataset.id, 'edit'] : ['new-dataset'];
    this.router.navigate(['/private_commons', 'project', this.projectId].concat(path));
  }

  /**
   * Casts any object to a CommonsSearchDataset object, which is needed for
   * properly getting type hints in the dataset table.
   */
  toTypedDs(obj: unknown): CommonsSearchDataset {
    return obj as CommonsSearchDataset;
  }

  get projectId(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  get user(): User {
    return this.userService.user;
  }

  editDatasetData(dataset: CommonsSearchDataset) {
    this.router.navigate(['/private_commons', 'project', this.projectId, 'dataset', dataset.id, 'spreadsheet']);
  }

  onFileComplete() {
    this.reloadEmit.emit();
  }

  uploadUrlDataset(dataset: CommonsSearchDataset) {
    return this.cas.getDatasetFileUrl(this.user, dataset);
  }

  #lockedCache: {[id: string]: Observable<boolean>} = {};
  isLocked(dataset: CommonsSearchDataset): Observable<boolean> {
    const lockedCache = this.#lockedCache;
    if (!(dataset.id in lockedCache)) {
      const feed = _isLocked(dataset, this.cas, this.user).pipe(shareReplay(1));
      lockedCache[dataset.id] = feed;
    }
    return lockedCache[dataset.id];
  }

  /** Deprecated code: May be re-instated
  async restoreDatasetData(dataset) {
    try {
      await lastValueFrom(this.cas.restoreDatasetData(dataset, this.user));
      // await this.loadProject();
    } catch (error) {
      console.error(error);
    }
    // await this.patchProject();
  }

  deleteDatasetData(dataset) {
    this.cas.deleteDatasetData(dataset, this.user).subscribe(
      //() => this.loadProject(),
      error1 => {
        console.error(error1);
      },
    );
  }
   */

  //TODO: Fix this to download file from correct bucket.
  async downloadFile(projectDataset: CommonsSearchDataset) {
    // Check if current user is on the list of IRB Investigators for this dataset
    const isActive = await lastValueFrom(this.cas.isUserIrbInvestigator(projectDataset, this.user));

    if (isActive) {
      // Open confirmation dialog first.
      const dialogRef = this.dialog.open(HsdDownloadDialogComponent, {
        height: '300px',
        width: '500px',
        data: {
          dataset: projectDataset,
          confirm: false,
          user: null,
        },
      });

      const data: HsdDownloadDialogData = await lastValueFrom(dialogRef.afterClosed());
      if (data.confirm) {
        const latestVersion = this.cas.getLatestDatasetFileVersion(projectDataset);
        const url = this.cas.getDatasetFileUrl(this.user, projectDataset);
        await this.cas.downloadFile(url, latestVersion.file_name, null);
      }
    }
  }

  async goDataset(id: string) {
    const commons = await this.userService.getCommons();
    await this.router.navigate([commons, 'project', this.projectId, 'dataset', id]);
  }

  showAddButton() {
    return !this.publicCommons && this.projectWithPermissions?._can_download_data === true;
  }

  checkForFile(dataset: CommonsSearchDataset) {
    return dataset.dataset_file_versions[0];
  }

  displayWarnings(dataset: CommonsSearchDataset) {
    if (!this.checkForFile(dataset)) {
      return [];
    } else return this.toTypedDs(dataset).dataset_file_versions[0].file_version.file_hipaa_warnings;
  }
}
