import {
  DatasetAggregationLevelID,
  DatasetCreate,
  DatasetPut,
  DatasetResponse,
  DatasetRoleID,
  DatasetType,
  IdentifierHipaaID,
  OtherSensitiveDataID,
  RedcapDatasetCreate,
} from '@services/landing-service';
import {SensitivityLevel} from '@shared/labels/sensitivity-level';
import {CommonsSearchDataset} from '@shared/types/commons-types';
import {User} from '@shared/types/user';
import {Observable, of, map} from 'rxjs';
import {CommonsApiService} from '@services/commons-api/commons-api.service';

// Return an object that implements DatasetResponse type
export const getBlankDataset = (projectId: string, user: User): DatasetCreate => {
  return {
    name: '',
    description: '',
    institutional_access: false,
    is_metadata_public: false,
    is_data_public: false,
    data_aggregation_id: DatasetAggregationLevelID.NOT_SELECTED,
    keywords: [],
    users: [],
    associated_projects: [],
    identifiers_hipaa: [],
    other_sensitive_data: [],
    source_organization: {name: user.institution.name},
    dataset_type: DatasetType.GENERIC,
  } as unknown as DatasetCreate;
};

// Returns a DatasetPut object from the given DatasetResponse or CommonsSearchDataset object
export const makeDatasetPutFromDatasetResponse = (
  user: User,
  projectId: string,
  d?: DatasetResponse | CommonsSearchDataset,
): DatasetCreate | DatasetPut => {
  if (!d) {
    return getBlankDataset(projectId, user) as DatasetCreate;
  }

  const genericDatasetCreate: DatasetCreate = {
    dataset_type: DatasetType.GENERIC,

    // Required fields
    associated_projects: d.associated_projects
      ? d?.associated_projects.map(p => ({project_id: p.project.id, is_primary_project: p.is_primary_project}))
      : [],
    data_aggregation_id:
      (d?.data_aggregation?.id as DatasetAggregationLevelID) || DatasetAggregationLevelID.NOT_SELECTED,
    description: d?.description || '',
    identifiers_hipaa: (d?.identifiers_hipaa?.map(i => i.id) as IdentifierHipaaID[]) || [],
    institutional_access: d?.institutional_access || false,
    is_data_public: d?.is_data_public || false,
    is_metadata_public: d?.is_metadata_public || false,
    keywords: d?.keywords?.map(k => (typeof k === 'string' ? k : k.text)) || [],
    name: d?.name || '',
    other_sensitive_data: d?.other_sensitive_data?.map(o => o.id) as OtherSensitiveDataID[],
    source_organization: d?.source_organization?.name
      ? {name: d?.source_organization?.name}
      : {name: user.institution.name},
    users: d?.dataset_users?.map(u => ({email: u.user.email, role: u.dataset_role_id as DatasetRoleID})) || [
      {email: user.email, role: DatasetRoleID.ADMIN},
    ],

    // Optional fields
    approved_irb_id: d?.associated_projects?.find(p => !!p.approved_irb)?.approved_irb?.id || undefined,
    contract_id: d?.associated_projects.find(p => !!p.contract)?.contract?.id || undefined,
    doi: d?.doi || undefined,
    dsp_id: d?.associated_projects.find(p => !!p.data_security_plan)?.data_security_plan?.id || undefined,
    license_id: d?.license?.id || undefined,
    link_to_external_dataset: d?.link_to_external_dataset || '',
    original_datasets: d?.original_datasets?.map(o => o.id) || [],
    spatial_coverage: d?.spatial_coverage || undefined,
    study_irb_number: d?.study_irb_number || undefined,
    temporal_coverage: d?.temporal_coverage || undefined,
    variables_measured: d?.variables_measured?.map(v => (typeof v === 'string' ? v : v.text)) || [],
  };

  if (d?.dataset_type === DatasetType.REDCAP) {
    return {
      ...genericDatasetCreate,

      // Required fields
      dataset_type: DatasetType.REDCAP,
      redcap_project_url: d?.redcap_metadata?.redcap_project_url || '',

      // Optional fields
      is_data_dictionary: d?.redcap_metadata?.is_data_dictionary || undefined,
      redcap_extract_data: d?.redcap_metadata?.redcap_extract_data || undefined,
      redcap_project_pi: d?.redcap_metadata?.redcap_project_pi || undefined,
      redcap_project_title: d?.redcap_metadata?.redcap_project_title || undefined,
      redcap_project_token: undefined,
      redcap_report_id: d?.redcap_metadata?.redcap_report_id || undefined,
    } as RedcapDatasetCreate;
  }

  if (d?.dataset_type === DatasetType.DICOM) {
    return {
      ...genericDatasetCreate,

      // Required fields
      dataset_type: DatasetType.DICOM,
      quality: d?.dicom_metadata.quality || '',
      bids_structure: d?.dicom_metadata.bids_structure || undefined,

      // Optional fields
      field_of_view: d?.dicom_metadata.field_of_view || undefined,
      field_strength: d?.dicom_metadata.field_strength || undefined,
      organ_name: d?.dicom_metadata.organ_name || undefined,
      scanner_manufacturer_name: d?.dicom_metadata.scanner_manufacturer_name || undefined,
      scanner_model_name: d?.dicom_metadata.scanner_model_name || undefined,
      study_date: d?.dicom_metadata.study_date || undefined,
    };
  }

  return genericDatasetCreate;
};

export const isLocked = (
  dataset: CommonsSearchDataset | DatasetResponse,
  cas: CommonsApiService,
  user: User,
): Observable<boolean> => {
  if (dataset.study_irb_number && isHSD(dataset)) {
    return cas.isUserIrbInvestigator(dataset, user).pipe(map((elm, idx) => !elm));
  }
  return of(false);
};

export const isHSD = (dataset: CommonsSearchDataset | DatasetResponse): boolean => {
  return SensitivityLevel.HSD === dataset.sensitivity_level.id;
};
