<app-commons-menu [navItems]="navItems" [title]="datasetTitle">
  <ng-container
    *ngTemplateOutlet="isContentLoadedAndOnVPN() ? pageContent : !loadingDone ? loadingMessage : noVPNMessage"
  ></ng-container>
</app-commons-menu>

<ng-template #pageContent>
  <div class="dataset" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="1em" fxLayoutWrap>
    <ng-container *ngIf="!emptyResults; else noPermissionsMessage">
      <div
        fxFlex.lg="75%"
        fxFlex.md="75%"
        fxFlex.sm="50%"
        fxFlex.xl="75%"
        fxFlex.xs="100%"
        fxLayoutGap="1em"
        class="mat-elevation-z1 left-col"
      >
        <div
          *ngIf="dataset && hasId"
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          class="button-row"
        >
          <button
            *ngIf="getDownloadURL() && this.datasetScanComplete && !downloadInProgress"
            color="primary"
            mat-raised-button
            type="button"
            (click)="downloadLatestDatasetFile()"
          >
            <mat-icon matTooltip="Click here to download the data file for this dataset ({{ getDownloadFileName() }})">
              file_download
            </mat-icon>
            Download Dataset File ({{ formatSize(fileVersions[0].file_size) }})
          </button>
          <button *ngIf="downloadInProgress" color="primary" mat-raised-button type="button" disabled="true">
            <div fxLayout="row">
              <mat-spinner diameter="32" fxLayout="fxLayout.row"></mat-spinner>
              File Downloading...
            </div>
          </button>
          <button>
            <a
              *ngIf="showExternalDatasetButton()"
              mat-raised-button
              [href]="dataset.link_to_external_dataset"
              target="_blank"
              color="primary"
              id="dataset-website"
              matTooltip="Click here to access the external dataset"
            >
              <mat-icon>exit_to_app</mat-icon>
              Access External Dataset
            </a>
          </button>
          <button
            *ngIf="datasetWithPermissions._can_update_meta"
            [routerLink]="['/private_commons', 'project', projectId, 'dataset', datasetId, 'edit']"
            color="accent"
            mat-raised-button
            matTooltip="Click here to edit dataset details/permissions"
          >
            <mat-icon> edit</mat-icon>
            EDIT DATASET DETAILS
          </button>
          <button
            *ngIf="datasetWithPermissions._can_upload_data && datasetScanComplete"
            color="primary"
            mat-raised-button
            (click)="uploadFile()"
          >
            <mat-icon>file_upload</mat-icon>
            UPLOAD FILE
          </button>
          <button
            *ngIf="datasetWithPermissions._can_upload_data && dataset.dataset_file_versions.length > 0"
            [disabled]="true"
            [routerLink]="['/private_commons', 'project', projectId, 'dataset', datasetId, 'spreadsheet']"
            color="accent"
            mat-raised-button
            matTooltip="This feature is temporarily disabled"
          >
            <mat-icon> grid_on</mat-icon>
            EDIT DATASET DATA
          </button>
          <button
            type="button"
            mat-raised-button
            *ngIf="
              datasetWithPermissions._can_delete_meta && datasetWithPermissions._can_delete_data && !showConfirmDelete
            "
            (click)="showConfirmDelete = true"
            color="warn"
          >
            DELETE DATASET
          </button>
          <button *ngIf="showConfirmDelete" type="button" mat-raised-button (click)="deleteDataset()" color="warn">
            <mat-icon>delete</mat-icon>
            Permanently Delete This Dataset!!!
          </button>
          <button
            (click)="showConfirmDelete = false"
            *ngIf="
              showConfirmDelete && datasetWithPermissions._can_delete_meta && datasetWithPermissions._can_delete_data
            "
            mat-button
            [ngClass]="'cancel-delete'"
          >
            <mat-icon>cancel</mat-icon>
            Cancel
          </button>
        </div>
        <div style="margin-top: 2rem; padding-top: 2rem">
          <markdown [data]="dataset.description" class="description"></markdown>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="home-institution"
            *ngIf="homeInstitution && homeInstitution.name !== 'Other Source / External'"
          >
            <img
              alt="{{ homeInstitution.name }}"
              matTooltip="{{ homeInstitution.name }}"
              src="{{ homeInstitution.image }}"
            />
          </div>
        </div>

        <section *ngIf="user && hasId && datasetWithPermissions._can_upload_data">
          <h2>Published Status of Current Dataset File Version</h2>
          <div *ngIf="allowPublishing(); else freezePublishing">
            <mat-expansion-panel *ngIf="!canPublish() && this.datasetScanComplete" #canPublishPanel>
              <mat-expansion-panel-header>
                <mat-panel-title class="frozen-warning">
                  <h4>
                    The <em>Publish Dataset File</em> feature is frozen at this time because this Dataset does not yet
                    meet the requirements for publishing.
                  </h4>
                  <button mat-icon-button color="primary">
                    <mat-icon>help</mat-icon>
                  </button>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <h3>
                The <em>Publish Dataset File</em> feature is frozen at this time because this Dataset does not meet
                either of these acceptable options for public Datasets:
              </h3>

              <div *ngFor="let option of publishingOptions" class="publishing-options">
                <h4>{{ option.title }}</h4>
                <ul>
                  <li
                    *ngFor="let criterion of option.criteria"
                    class="criterion {{ criterion.isMet ? 'criterion-met' : 'criterion-failed' }}"
                  >
                    <mat-icon mat-list-icon>{{ criterion.isMet ? 'check' : 'close' }}</mat-icon>
                    <markdown>{{ criterion.label }}</markdown>
                  </li>
                </ul>
              </div>

              <div *ngIf="hasFileHipaaWarnings()">
                IN ADDITION, CONTACT THE SYSTEM ADMINISTRATOR TO ADDRESS THE HIPAA WARNING BEFORE ATTEMPTING TO PUBLISH
                THIS DATASET FILE.
              </div>
            </mat-expansion-panel>
            <div *ngIf="!this.datasetScanComplete">
              <h4 class="frozen-warning">
                The latest dataset file is undergoing a security scan. Publishing options are locked at this time.
              </h4>
            </div>

            <div *ngIf="!datasetDataToggleIsLoading; else publishingMessage" style="margin-top: 1rem">
              <app-public-private-toggle
                (toggle)="toggleDataPrivate()"
                *ngIf="user && datasetWithPermissions._can_manage_permission"
                [disabled]="!canPublish()"
                [selected_option]="dataset.is_data_public ? 'PUBLIC' : 'PRIVATE'"
                [options]="dataToggleOptions"
                tooltipPrivate="Only dataset admins and collaborators can view the dataset data."
                tooltipPublic="Anyone can view dataset data."
              ></app-public-private-toggle>
            </div>

            <div id="fileTable"></div>
            <div *ngIf="dataset.is_metadata_public && userPermissions?.customer?.length > 0">
              Note: Published current file to below individual users only:
              <table
                [dataSource]="userPermissions.customer"
                class="datasetperms"
                aria-describedby="User permissions"
                mat-table
              >
                <ng-container matColumnDef="email">
                  <th *matHeaderCellDef mat-header-cell>EMAIL</th>
                  <td *matCellDef="let userPermission" mat-cell>
                    <mat-label>
                      {{ toTypedUP(userPermission).user.email }}
                    </mat-label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="role">
                  <th *matHeaderCellDef mat-header-cell>ROLE</th>
                  <td *matCellDef="let userPermission" mat-cell>
                    {{ lookupRole(toTypedUP(userPermission).dataset_role_id) }}
                  </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedUserPermissionColumns" mat-header-row></tr>
                <tr
                  *matRowDef="let row; let odd = odd; columns: displayedUserPermissionColumns"
                  [ngClass]="{gray: odd}"
                  mat-row
                ></tr>
              </table>
            </div>
          </div>
        </section>

        <section
          *ngIf="
            user && hasId && datasetWithPermissions._can_upload_data && datasetWithPermissions.dataset_file_versions
          "
        >
          <h2>Dataset File Versions</h2>
          <ng-container *ngIf="fileVersions; else loadingFileVersionsMessage">
            <ng-container *ngIf="fileVersions.length > 0; else noFilesMessage">
              <p *ngIf="isLocked(dataset) | async">
                The previously uploaded files associated with this dataset are not available at this time. This is due
                to the fact that the IRB protocol linked to this dataset does not have an ACTIVE status. Please
                communicate with the IRB staff to reactivate your IRB protocol.
              </p>
              <table [dataSource]="fileVersions" style="margin-bottom: 1rem" aria-describedby="File versions" mat-table>
                <ng-container matColumnDef="file_version">
                  <th *matHeaderCellDef mat-header-cell>FILE NAME</th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    <mat-label>
                      {{ toTypedFV(fileVersionDetail).file_name }}
                    </mat-label>
                  </td>
                </ng-container>
                <ng-container matColumnDef="file_hipaa_warning">
                  <th *matHeaderCellDef mat-header-cell></th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    <app-commons-hipaa-warning
                      [dataset]="dataset"
                      [warnings]="toTypedFV(fileVersionDetail).file_hipaa_warnings"
                      [isScanComplete]="!showScannerProgress(fileVersionDetail)"
                    ></app-commons-hipaa-warning>
                  </td>
                </ng-container>
                <ng-container matColumnDef="creator">
                  <th *matHeaderCellDef mat-header-cell>CREATOR</th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    {{ toTypedFV(fileVersionDetail).uploaded_by.email }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="create_date_time">
                  <th *matHeaderCellDef mat-header-cell>CREATE DATE/TIME</th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    {{ toTypedFV(fileVersionDetail).time_created | date: 'MM/d/y h:mm a' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="download">
                  <th *matHeaderCellDef mat-header-cell>DOWNLOAD</th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    <a
                      (click)="
                        downloadFile(toTypedFV(fileVersionDetail).file_name, user, toTypedFV(fileVersionDetail).id)
                      "
                    >
                      <button
                        color="primary"
                        mat-mini-fab
                        type="button"
                        [disabled]="showScannerProgress(fileVersionDetail)"
                      >
                        <mat-icon
                          matTooltip="Click here to download file ({{ toTypedFV(fileVersionDetail).file_name }})"
                        >
                          file_download
                        </mat-icon>
                      </button>
                      ({{ formatSize(toTypedFV(fileVersionDetail).file_size) }})
                    </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="delete">
                  <th *matHeaderCellDef mat-header-cell>DELETE</th>
                  <td *matCellDef="let fileVersionDetail" mat-cell>
                    <a (click)="confirmDelete(toTypedFV(fileVersionDetail).file_name, toTypedFV(fileVersionDetail).id)">
                      <button
                        color="warn"
                        mat-mini-fab
                        type="button"
                        [disabled]="
                          (isLatestFile(fileVersionDetail) && dataset.is_data_public === true) ||
                          showScannerProgress(fileVersionDetail)
                        "
                      >
                        <mat-icon [matTooltip]="getDeleteTooltip(fileVersionDetail)"> delete </mat-icon>
                      </button>
                    </a>
                  </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedFileVersionColumns" mat-header-row></tr>
                <tr
                  *matRowDef="let row; let odd = odd; columns: displayedFileVersionColumns"
                  [ngClass]="{gray: odd}"
                  mat-row
                ></tr>
              </table>
            </ng-container>
          </ng-container>
        </section>
        <button
          *ngIf="datasetWithPermissions._can_upload_data && datasetScanComplete"
          color="primary"
          mat-raised-button
          (click)="uploadFile()"
        >
          <mat-icon>file_upload</mat-icon>
          UPLOAD FILE
        </button>
      </div>
      <div
        fxFlex.lg="25%"
        fxFlex.md="25%"
        fxFlex.sm="50%"
        fxFlex.xl="25%"
        fxFlex.xs="100%"
        fxLayout="column"
        fxLayoutGap="1em"
        class="mat-elevation-z1 right-col"
      >
        <h2>Dataset Details</h2>
        <app-public-private-toggle
          (toggle)="toggleMetadataPrivate()"
          *ngIf="user && datasetWithPermissions._can_manage_permission"
          [selected_option]="dataset.is_metadata_public ? 'PUBLIC' : 'PRIVATE'"
          [disabled]="dataset.is_data_public === true"
          tooltipPrivate="Click here to allow only dataset administrators and collaborators to view the dataset details."
          tooltipPublic="Click here to allow all iTHRIV users to view the dataset details."
        ></app-public-private-toggle>
        <app-dataset-details-card [dataset]="dataset"></app-dataset-details-card>

        <div *ngIf="user && hasId && datasetWithPermissions._can_upload_data" class="dataset-team">
          <h2>Dataset Team</h2>
          <mat-list dense *ngIf="userPermissions">
            <table [dataSource]="userPermissions.all" class="datasetperms" aria-describedby="Dataset team" mat-table>
              <ng-container matColumnDef="email">
                <th *matHeaderCellDef mat-header-cell>EMAIL</th>
                <td *matCellDef="let userPermission" mat-cell>
                  <mat-label>
                    {{ toTypedUP(userPermission).user.email }}
                  </mat-label>
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th *matHeaderCellDef mat-header-cell>ROLE</th>
                <td
                  *matCellDef="let userPermission"
                  [matTooltip]="getRoleTooltip(lookupRole(toTypedUP(userPermission).dataset_role_id))"
                  mat-cell
                >
                  {{ lookupRole(toTypedUP(userPermission).dataset_role_id) }}
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedUserPermissionColumns" mat-header-row></tr>
              <tr
                *matRowDef="let row; let odd = odd; columns: displayedUserPermissionColumns"
                [ngClass]="{gray: odd}"
                mat-row
              ></tr>
            </table>
          </mat-list>
          <button
            (click)="showDatasetPermissions()"
            *ngIf="user && datasetWithPermissions._can_manage_permission"
            color="primary"
            mat-raised-button
            matTooltip="Click here to edit dataset details/permissions"
          >
            <mat-icon>security</mat-icon>
            EDIT PERMISSIONS
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadingMessage>
  <app-loading message="Loading dataset..." size="sm"></app-loading>
</ng-template>

<ng-template #savingChangesMessage>
  <app-loading message="Saving changes..." size="sm"></app-loading>
</ng-template>

<ng-template #publishingMessage>
  <div style="max-width: 850px">
    <mat-progress-bar
      [mode]="transferInProgress ? 'buffer' : 'indeterminate'"
      [value]="uploadedAmount"
      [bufferValue]="downloadedAmount"
    ></mat-progress-bar>
    <div style="text-align: center">Publishing {{ fileVersions[0].file_name }}...</div>
    <h4>File transfer in progress. Do not close this browser tab until this process is complete.</h4>
  </div>
</ng-template>

<ng-template #loadingFileVersionsMessage>
  <app-loading message="Loading dataset file versions..." size="sm"></app-loading>
</ng-template>

<ng-template #noFilesMessage><p>No data files have been uploaded.</p></ng-template>

<ng-template #freezePublishing>
  <mat-card-subtitle>
    The <em>Publish Dataset File</em> feature is frozen at this time pending governance decisions.
  </mat-card-subtitle>
</ng-template>

<ng-template #noPermissionsMessage>
  <mat-card>
    <mat-card-content>
      <h2>Dataset Access Denied</h2>
      <p>You do not have access to this dataset.</p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #noVPNMessage>
  <app-vpn-warning></app-vpn-warning>
</ng-template>
